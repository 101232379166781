@charset 'utf-8';

@import "settings";
@import "foundation";
@import "motion-ui";

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-range-input;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;

// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

// Fonts
//------

@font-face {
  font-family: "Raleway";
  src: url("../fonts/raleway-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/raleway-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/raleway-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/raleway-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

// Variables
//----------

// Styles
//-------

body {
  font-size: 20px;
  font-weight: 300;
}

header {
  width: 100%;
  height: 130px;
  background: transparent;
}

main {
  margin-top: 120px;
}

legend {
  display: none;
}

a:link {
  background: scale-color($primary-color, $lightness: 80%);
  border-bottom: 1px dashed $secondary-color;
  border-radius: 3px;
  color: $black;
}

a:hover {
  background: scale-color($primary-color, $lightness: 40%);
  color: $secondary-color;
}

a:visited {
  background: scale-color($primary-color, $lightness: 80%);
  color: $secondary-color;
}

.hero {
  background: url("../images/hero.jpg") repeat;
  background-size: cover;
  min-height: 720px;
  position: relative;
  width: 100%;
  @include breakpoint(small only) {
    min-height: 640px;
  }

  & .caption {
    margin-top: 120px;
    position: relative;
    text-transform: uppercase;
    text-shadow: 0 0 60px $black;

    & h1.mean_caption {
      color: $white;
      font-size: 72px;
      font-weight: 700;
      @include breakpoint(small only) {
        font-size: 44px;
      }
    }

    & h2.sub_caption {
      color: $white;
      font-family: "Raleway", Helvetica, Arial, sans-serif;
      font-size: 40px;
      margin-bottom: 50px;
      @include breakpoint(small only) {
        font-size: 32px;
      }
    }
  }
}

.hero_checkup {
  background: url("../images/hero-checkup.jpg") repeat;
  background-size: cover;
}

.hero_disclaimer {
  background: url("../images/hero-disclaimer.jpg") repeat;
  background-size: cover;
}

.hero_error {
  background: url("../images/hero-error.jpg") repeat;
  background-size: cover;
}

.rellax {
  position: absolute;
  top: 0;
  min-height: 100vh;
  min-width: 100vh;
}

.logo {
  margin-top: 60px;
  text-align: left;
  transition: 0.5s;
  width: 128px;

  &:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  & a {
    background: none;
    border-bottom: none;
  }
}

.logo {
  position: relative;
}

.logo_text {
  text-transform: uppercase;
}

.menu {
  margin-top: 60px;

  & .active {
    color: $primary-color;
  }

  & a {
    background: none;
    border-bottom: none;
    color: $white;
    margin-top: 30px;
    text-shadow: 0 0 15px $black;
    text-transform: uppercase;
    @include breakpoint(small only) {
      margin-top: 15px;
    }
  }

  & a:hover {
    color: $primary-color;
  }
}

[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"],
textarea {
  appearance: none;
  border: 1px dashed $black;
  border-radius: 22px;
  font-size: 20px;
  height: 45px;
  margin: 5px 0 30px;
}

.mean_title,
.sub_title {
  color: $black;
  font-family: "Raleway", Roboto, Arial, sans-serif;
  margin: 30px 0;
}

.mean_title {
  font-size: 32px;
  font-family: "Raleway", Roboto, Arial, sans-serif;
  font-weight: 700;
  margin: 30px 0;
  text-transform: uppercase;
}

.sub_title {
  font-size: 24px;
  font-family: "Raleway", Roboto, Arial, sans-serif;
  font-weight: 300;
}

.serv_title {
  color: $black;
  font-family: "Raleway", Roboto, Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 15px 0;
}

.circle_img {
  border-radius: 50%;
}

article.what,
article.why {
  margin: 60px 0;
  text-align: center;
}

article.who {
  margin: 60px 0;
  text-align: center;

  & p {
    font-family: "Raleway", Roboto, Arial, sans-serif;
  }
}

article.checkup {
  margin: 60px 0 120px;
  text-align: center;

  & form {
    margin: 60px 0;
    text-align: left;
  }
}

article.cost {
  margin: 60px 0;
  text-align: left;
}

article.disclaimer {
  margin: 60px 0 120px;
  text-align: center;
}

article.error {
  margin: 60px 0 120px;
  text-align: center;
}

article.where {
  margin: 60px 0;
  text-align: right;
}

.oldtimer_checkup {
  background: url("../images/cubes.png") repeat;
  margin-top: 120px;
  padding: 60px 0 120px;
  text-align: center;

  & table {
    margin: 60px 0;
    text-align: left;
  }

  & .title {
    color: $black;
    font-family: "Raleway", Roboto, Arial, sans-serif;
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  & .sub_title {
    color: $black;
    font-size: 18px;
    text-transform: lowercase;
  }
}

.oldtimer_checkup_info {
  color: #5c5c5c;
  font-family: "Raleway", Roboto, Arial, sans-serif;
  font-size: 16px;
  margin: 60px 0;
  text-align: center;
}

.btn_holder {
  text-align: center;

  & .btn_fancy {
    background: transparent;
    color: $black;
    display: inline-block;
    font-family: "Raleway", Roboto, Arial, sans-serif;
    font-size: 32px;
    font-weight: 700;
    height: 90px;
    line-height: 90px;
    margin: 60px 0;
    padding: 3px 90px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s ease-out;
    @include breakpoint(small only) {
      font-size: 20px;
      padding: 3;
    }

    & .text_layer {
      position: relative;
    }

    & .border_layer {
      border-radius: 45px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px dashed $black;
      transition: all 0.2s ease-out;
    }

    & .solid_layer {
      border-radius: 45px;
      position: absolute;
      top: 6px;
      left: -6px;
      width: 100%;
      height: 100%;
      background: $primary-color;
      transition: all 0.2s ease-out;
    }

    &:focus,
    &:hover {
      color: $black;

      & .border_layer {
        transform: translate(2px, -2px);
      }

      & .solid_layer {
        transform: translate(-2px, 2px);
      }
    }
  }

  & a {
    background: none;
    border-bottom: none;
  }
}

.telephone {
  font-family: "Raleway", Roboto, Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  margin-top: -30px;
  margin-bottom: 60px;
  text-align: center;
}

.button,
[type="submit"] {
  background: $primary-color;
  border-radius: 22px;
  border: none;
  color: $black;
  font-family: "Raleway", Roboto, Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 45px;
  padding: 0 30px;

  &:hover {
    color: $black;
  }
}

.map {
  width: 100%;
  height: 550px;
  position: relative;
  overflow: hidden;

  & .shadow {
    width: 100%;
    height: 158px;
    background: url("../images/shadow.png") repeat-x;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 90;
  }

  & #map_container {
    background: url("../images/osmap.jpg") center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;

    & .map_contacts {
      color: $black;

      & span {
        font-family: "Raleway", Helvetica, Arial, sans-serif;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    & img {
      max-width: none;
    }
  }
}

.comError {
  color: $alert-color;
}

.comThanks {
  font-weight: 700;
  margin: 60px 0;
}

footer {
  color: $white;
  padding: 80px 0;
  background: $secondary-color;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-size: 14px;

  svg {
    display: inline;
  }
  & .copyrights {
    float: left;
    margin-bottom: 80px;

    & p {
      font-size: 12px;
      letter-spacing: 0.5px;

      & a {
        background: none;
        border-bottom: none;
        color: $white;

        &:hover {
          color: #c5c5c5;
        }
      }
    }
  }

  & a:link,
  & a:hover {
    color: $white;
  }

  & .fa {
    color: $white;
    height: 1rem;
    text-decoration: none;
  }
}

footer .logo_text {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

footer .contact_details {
  max-width: 430px;
  float: right;
}

footer .social {
  width: 130px;
  display: inline-block;

  & ul {
    margin: 0;
  }

  & .fa {
    margin-right: 10px;
  }

  & a {
    background: none;
    color: $white;

    &:hover {
      color: #c5c5c5;
    }
  }
}

footer .contact {
  display: inline-block;
  letter-spacing: 0.5px;
  a {
    background: none;
    color: $white;

    &:hover {
      color: #c5c5c5;
    }
  }
}

footer .contact {
  width: 215px;
  margin-left: 80px;
  vertical-align: top;

  & .details {
    margin-bottom: 30px;
  }
}

footer p {
  margin: 0;
  padding: 0;
}

// Media Queries
//--------------
